import { Unit } from '@apimmo/front/components/Typography'
import { borderRadius, spacings, transition } from '@apimmo/front/style/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, Ref } from 'react'
import { colors, globalStyle } from '~/style/theme'
import { InputGroup, InputGroupProps } from './InputGroup'

type StyledInputProps = {
  error?: boolean
  withIcon?: boolean
  readOnly?: boolean
}

export const BaseInput = css`
  display: block;
  width: 100%;
  min-height: ${spacings.xlarge};
  padding: ${spacings.xsmall} ${spacings.small};
  border-radius: ${borderRadius};
  outline: 0;
  font-size: 1rem;
  transition: ${transition.global};
  appearance: none;

  &:focus:not(:disabled) {
    box-shadow: ${globalStyle.shadowFocus};
    border-color: ${colors.primary};
    outline: none;
    background-color: ${colors.white};
  }

  &:disabled {
    color: ${colors.grey200};
    cursor: not-allowed;
  }
`

export const StyledInput = styled.input<StyledInputProps>`
  ${BaseInput};
  padding-right: ${({ withIcon }) => withIcon && spacings.xlarge};
  border: 1px solid
    ${({ error }) => (error ? colors.warningDark : 'transparent')};
  background-color: ${({ error }) =>
    error ? colors.warningLight : colors.grey50};
  cursor: ${({ readOnly }) => readOnly && 'not-allowed'};
`

const InputWrapper = styled.div`
  position: relative;
`

const InputIcon = styled(Unit)`
  position: absolute;
  top: 50%;
  right: ${spacings.small};
  transform: translateY(-50%);

  &::first-letter {
    text-transform: initial;
  }
`

export type InputProps = Omit<ComponentProps<'input'>, 'ref'> & InputGroupProps

export const Input = React.forwardRef(
  (
    {
      name,
      label,
      labelSecondary,
      icon,
      helperText,
      errorText,
      help,
      fullWidth,
      ...otherProps
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => (
    <InputGroup
      name={name}
      fullWidth={fullWidth}
      label={label}
      labelSecondary={labelSecondary}
      helperText={helperText}
      help={help}
      errorText={errorText}
    >
      <InputWrapper>
        <StyledInput
          {...otherProps}
          id={name}
          name={name}
          error={!!errorText}
          withIcon={!!icon}
          ref={ref}
        />
        {icon && <InputIcon>{icon}</InputIcon>}
      </InputWrapper>
    </InputGroup>
  ),
)
