import { AxiosError } from 'axios'
import { FormikErrors } from 'formik'
import { map } from 'ramda'

type ValidationError = {
  message: 'string'
  errors: Record<string, string[]>
}

type ValidationKeysMapping = Record<string, string>

export const extractValidationErrors = (
  e: AxiosError<ValidationError>,
  validationKeysMapping: ValidationKeysMapping = {},
): FormikErrors<any> => {
  const { isAxiosError, response } = e

  if (isAxiosError && response && response.status === 422) {
    const {
      data: { errors },
    } = response

    if (!validationKeysMapping) return errors

    const newErrors = Object.keys(validationKeysMapping).reduce(
      (currentErrors, key) => ({
        ...currentErrors,
        [key]: errors[validationKeysMapping[key]],
      }),
      {} as Record<string, string[]>,
    )

    return map((value) => value && value.join(', '), {
      ...errors,
      ...newErrors,
    })
  }

  throw e
}
