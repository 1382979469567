import { curry, intersection } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectAchatById } from '~/store/achats/achats.selectors'
import { isExistingAchat } from '~/store/achats/achats.utils'
import { selectConnectedUserId } from '~/store/auth/auth.selectors'
import { selectBienByVenteId } from '~/store/biens/biens.selectors'
import { Permissions } from '~/store/permissions/permissions.types'
import { selectSecteurById } from '~/store/secteurs/secteurs.selectors'
import { Secteur } from '~/store/secteurs/secteurs.types'
import { getListOf } from '~/store/selectors'
import { selectTachesListFromIds } from '~/store/taches/taches.selectors'
import { RootState } from '~/store/types'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { Collaborateur, VisitIntentionPolicyType } from './collaborateurs.types'
import { formatCollaborateursAsOptions } from './collaborateurs.utils'
import { selectActiviteEffectueesByType } from '~/store/activitesEffectuees/activitesEffectuees.selector'
import { unique } from '~/utils/arrays'
import { ActionType } from '~/store/activitesEffectuees/activitesEffectuees.types'
import { Variant } from '../achats/achats.types'

export const selectCollaborateurById = curry(
  (collaborateurId: string, { collaborateurs }: RootState) =>
    collaborateurs.entities[collaborateurId],
)

export const selectCollaborateursFromIds = curry(
  (collaborateurIds: string[], state: RootState) =>
    compact(collaborateurIds.map((id) => selectCollaborateurById(id, state))),
)

export const selectCollaborateurAchatVenteFromVille = curry(
  (ville: string, state: RootState) => {
    const secteurs = Object.values(state.secteurs.entities) as Secteur[]
    const secteur = secteurs.find(
      ({ ville: secteurVille }) => secteurVille === ville,
    )

    if (!secteur?.collaborateurAchatVenteId) return null

    return selectCollaborateurById(secteur.collaborateurAchatVenteId, state)
  },
)

export const selectCollaborateurLocationFromVille = curry(
  (ville: string|undefined, state: RootState) => {
    const secteurs = Object.values(state.secteurs.entities) as Secteur[]
    const secteur = secteurs.find(
      ({ ville: secteurVille }) => secteurVille === ville,
    )

    if (!secteur?.collaborateurLocationId) return null

    return selectCollaborateurById(secteur.collaborateurLocationId, state)
  },
)

export const selectCollaborateurFromVilleAndVariant = curry(
  (ville: string|undefined, variant: Variant|undefined, state: RootState) => {
    if (variant === Variant.location) {
      return selectCollaborateurLocationFromVille(ville, state)
    }

    return selectCollaborateurAchatVenteFromVille(ville, state)
  },
)

export const selectCollaborateurFromVenteBienVille = curry(
  (venteId: string, state: RootState) => {
    const bien = selectBienByVenteId(venteId, state)
    if (!bien) return null

    return selectCollaborateurAchatVenteFromVille(bien.ville, state)
  },
)

export const selectCollaborateursAsOptions = (state: RootState) =>
  formatCollaborateursAsOptions(getListOf('collaborateurs', state))

export const selectCollaborateurs = (state: RootState) =>
  getListOf('collaborateurs', state)

export const selectCollaborateursForPermission = curry(
  (permission: string, state: RootState) => {
    const collaborateurs = selectCollaborateurs(state)

    return collaborateurs.filter((collaborateur) => collaborateur.$permissions?.[permission])
  },
)

export const selectCollaborateursForPermissionAsOptions = curry(
  (permission: string, state: RootState) => {
    const collaborateurs = selectCollaborateursForPermission(permission, state)

    return formatCollaborateursAsOptions(collaborateurs)
  },
)

export const selectCollaborateurByVenteId = curry(
  (venteId: string, state: RootState) => {
    const vente = selectVenteById(venteId, state)
    if (!vente) return null

    return selectCollaborateurById(vente.collaborateur, state)
  },
)

export const selectCollaborateurByAchatId = curry(
  (achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)
    if (!achat || !isExistingAchat(achat) || !achat.collaborateur) return null

    return selectCollaborateurById(achat.collaborateur, state)
  },
)

export const selectConnectedCollaborateur = (state: RootState) => {
  const connectedUserId = selectConnectedUserId(state)

  if (!connectedUserId) return null

  return selectCollaborateurById(connectedUserId, state)
}

export const selectCollaborateurAssociatedToContactAndVille = curry(
  (gerant: string, ville: string, state: RootState) => {
    if (gerant) {
      return selectCollaborateurById(gerant, state)
    }

    return selectCollaborateurAchatVenteFromVille(ville, state)
  },
)

export const selectCollaborateurLocationBySecteur = curry(
  (secteurId: string, state: RootState) => {
    const secteur = selectSecteurById(secteurId, state)
    if (!secteur || !secteur.collaborateurLocationId) return null

    return selectCollaborateurById(secteur.collaborateurLocationId, state)
  },
)

export const selectHasConnectedCollaborateurPermission = curry(
  (permission: Permissions, state: RootState) => {
    const collaborateur = selectConnectedCollaborateur(state)

    if (!collaborateur || !collaborateur.permissions) return false

    return collaborateur.permissions?.includes(permission)
  },
)

export const selectHasConnectedCollaborateurOnePermissionOf = curry(
  (permissions: Permissions[], state: RootState) => {
    const collaborateur = selectConnectedCollaborateur(state)

    if (!collaborateur || !collaborateur.permissions) return false

    return intersection(collaborateur.permissions, permissions).length > 0
  },
)

export const selectIsConnectedCollaborateurAchatCollaborateur = curry(
  (achatId: string, state: RootState) => {
    const collaborateurId = selectConnectedUserId(state)
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat) || !collaborateurId) return false

    return achat.collaborateur === collaborateurId
  },
)

export const selectCollaborateurIdByVenteId = curry(
  (venteId: string, state: RootState) => {
    const vente = selectVenteById(venteId, state)
    if (!vente) return null

    return vente.collaborateur
  },
)

export const selectCollaborateursAchatsVente = (state: RootState) => {
  const collaborateursAchatVente = state.collaborateurs.collaborateursAchatVenteIds.map(
    (id) => selectCollaborateurById(id, state),
  )
  return collaborateursAchatVente || []
}

export const selectCollaborateursLocation = (state: RootState) => {
  const collaborateursLocation = state.collaborateurs.collaborateursLocationIds.map(
    (id) => selectCollaborateurById(id, state),
  )
  return collaborateursLocation || []
}

export const selectCollaborateursAchatsVenteAsOptions = (state: RootState) =>
  formatCollaborateursAsOptions(
    selectCollaborateursAchatsVente(state) as Collaborateur[],
  )

export const selectCollaborateursAchatsVenteAsOptionsWithoutConnected = (
  state: RootState,
) => {
  const collaborateurs = selectCollaborateursAchatsVente(state)
  const connectedCollaborateur = selectConnectedCollaborateur(state)

  const collaborateursFiltered = collaborateurs.filter(
    (collaborateur) => collaborateur !== connectedCollaborateur,
  )

  return formatCollaborateursAsOptions(
    collaborateursFiltered as Collaborateur[],
  )
}

export const selectCollaborateursLocationAsOptions = (state: RootState) =>
  formatCollaborateursAsOptions(
    selectCollaborateursLocation(state) as Collaborateur[],
  )

  export const selectCollaborateursLocationAsOptionsWithoutConnected = (
    state: RootState,
  ) => {
    const collaborateurs = selectCollaborateursLocation(state)
    const connectedCollaborateur = selectConnectedCollaborateur(state)
  
    const collaborateursFiltered = collaborateurs.filter(
      (collaborateur) => collaborateur !== connectedCollaborateur,
    )
  
    return formatCollaborateursAsOptions(
      collaborateursFiltered as Collaborateur[],
    )
  }

export const selectCollaborateursIdsFromTasksList = curry(
  (tachesIds: string[], state: RootState): string[] => {
    const taches = selectTachesListFromIds(tachesIds, state)

    if (!taches) return []

    return taches.map(({ collaborateurId }) => collaborateurId)
  },
)

export const selectCollaborateursListFromIds = curry(
  (collaborateursIds: string[], state: RootState): Collaborateur[] =>
    compact(collaborateursIds.map((id) => selectCollaborateurById(id, state))),
)

export const selectCollaborateurAchatUpdatePolicy = curry(
  (collaborateurId: string, achatId: string, state: RootState) => {
    const collaborateur = selectCollaborateurById(collaborateurId, state)

    if (!collaborateur) return false

    const achatsPoliciesCollaborateur = collaborateur.achatsPolicies || []

    const achatPolicy = achatsPoliciesCollaborateur.find(
      (policy) => policy.achatId === achatId && policy.type === 'update',
    )

    return achatPolicy?.value || false
  },
)

export const selectConnectedCollaborateurAchatUpdatePolicy = curry(
  (achatId: string, state: RootState) => {
    const collaborateurId = selectConnectedUserId(state)

    if (!collaborateurId) return false

    const hasPolicy = selectCollaborateurAchatUpdatePolicy(
      collaborateurId,
      achatId,
      state,
    )

    return hasPolicy
  },
)

const selectHasCollaborateurVisitIntentionPolicy = curry(
  (
    collaborateurId: string,
    achatId: string,
    venteId: string,
    state: RootState,
  ) => {
    const collaborateur = selectCollaborateurById(collaborateurId, state)

    if (!collaborateur) return false

    const collaborateurVisitIntentionPolicies =
      collaborateur.visitIntentionPolicies || []

    const visitIntentionPolicy = collaborateurVisitIntentionPolicies.find(
      (policy) =>
        policy.achatId === achatId &&
        policy.venteId === venteId &&
        policy.type === VisitIntentionPolicyType.create,
    )

    return visitIntentionPolicy?.value || false
  },
)

export const selectHasVisitIntentionPolicy = curry(
  (achatId: string, bienId: string, state: RootState) => {
    const collaborateurId = selectConnectedUserId(state)
    if (!collaborateurId) return false

    const hasVisitIntentionPolicy = selectHasCollaborateurVisitIntentionPolicy(
      collaborateurId,
      achatId,
      bienId,
      state,
    )

    return hasVisitIntentionPolicy
  },
)

export const selectUniqueCollaborateursFromActiviteeEffectueeListFilteredByActionType = curry(
  (
    activiteEffectueeIds: string[],
    actionType: ActionType,
    state: RootState,
  ) => {
    const activitesEffectuees = selectActiviteEffectueesByType(
      activiteEffectueeIds,
      actionType,
      state,
    )

    const collaborateurIds = unique(
      activitesEffectuees.map(
        (activiteEffectuee) => activiteEffectuee?.collaborateurId,
      ),
    )

    return compact(
      collaborateurIds.map((collaborateurId) =>
        selectCollaborateurById(collaborateurId || '', state),
      ),
    )
  },
)
