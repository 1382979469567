import {
  borderRadius,
  colors,
  mediaQueries,
  modalWidth,
  spacings,
  zIndex,
} from '@apimmo/front/style/theme'
import styled from '@emotion/styled'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(${zIndex.modal} + 1);
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`

type ContainerProps = {
  size?: 'fullscreen' | 'default' | 'small' | 'large'
  fullscreen?: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  margin-right: auto;
  margin-left: auto;
  height: fit-content;
  padding: ${spacings.large};
  min-height: ${({ size }) => (size === 'fullscreen' ? '100%' : 'initial')};
  z-index: ${zIndex.modal};
  backface-visibility: hidden;
  border-radius: ${({ size }) => (size === 'fullscreen' ? 0 : borderRadius)};
  background-color: ${colors.white};

  ${mediaQueries.medium} {
    margin-top: ${({ size }) => (size === 'fullscreen' ? 0 : spacings.large)};
    margin-bottom: ${({ size }) =>
      size === 'fullscreen' ? 0 : spacings.large};
    max-width: ${({ size }) => {
      if (size === 'fullscreen') return '100%'
      if (size === 'large') return modalWidth.large
      if (size === 'small') return modalWidth.small
      return modalWidth.default
    }};
  }
`
