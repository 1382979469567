import Icon from '@apimmo/front/components/icons/Icon'
import { ColumnFlex, RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import {
  Label,
  TextSecondarySmall,
  TextSmall,
} from '@apimmo/front/components/Typography'
import { Spacing, spacings, transition } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import React, {
  ComponentProps,
  createElement,
  MouseEventHandler,
  ReactNode,
} from 'react'
import { colors } from '~/style/theme'
import { condition } from '~/utils/selectors'

const StyledInputGroup = styled(ColumnFlex)<{
  fullWidth?: boolean
  gutterTop?: Spacing
  gutterBottom?: Spacing
}>`
  display: ${condition('fullWidth', 'flex', 'inline-flex')};
  margin-top: ${({ gutterTop }) => gutterTop && spacings[gutterTop]};
  margin-bottom: ${({ gutterBottom }) =>
    gutterBottom && spacings[gutterBottom]};
`

// TODO: remove this useless component
const UnstyledLabel = ({
  ...props
}: ComponentProps<'label'> &
  ComponentProps<typeof Label> & { error?: boolean }) =>
  createElement(Label, {
    ...props,
    as: 'label',
  })

const InputLabel = styled(UnstyledLabel)`
  display: block;
  transition: ${transition.global};
`

const InputHelperText = styled(TextSmall)<{ error?: boolean }>`
  margin-left: ${spacings.xsmall};
  color: ${condition('error', colors.warningDark)};
`

export type InputGroupProps = {
  name: string
  label: React.ReactNode
  labelSecondary?: React.ReactNode
  icon?: React.ReactNode
  helperText?: React.ReactNode
  errorText?: React.ReactNode
  help?: string
  fullWidth?: boolean
  onClick?: MouseEventHandler<HTMLLabelElement> &
    MouseEventHandler<HTMLParagraphElement>
  gutterTop?: Spacing
  gutterBottom?: Spacing
}

export const InputGroup = ({
  errorText,
  label,
  labelSecondary,
  children,
  name,
  helperText,
  help,
  fullWidth,
  onClick,
  gutterTop,
  gutterBottom,
}: InputGroupProps & { children: ReactNode }) => (
  <StyledInputGroup
    fullWidth={fullWidth}
    gutterTop={gutterTop}
    gutterBottom={gutterBottom}
    gap="xxsmall"
    horizontalAlignment="stretch"
  >
    {label && (
      <InputLabel error={!!errorText} htmlFor={name} onClick={onClick}>
        {label}
        {labelSecondary && (
          <TextSecondarySmall>{labelSecondary}</TextSecondarySmall>
        )}
      </InputLabel>
    )}

    {children}
    {(helperText || errorText) && (
      <InputHelperText error={!!errorText}>
        {errorText || helperText}
      </InputHelperText>
    )}
    {help && (
      <RowFlex gap="xsmall">
        <Icon size="small" name="info" color="grey200" />
        <TextSmall>{help}</TextSmall>
      </RowFlex>
    )}
  </StyledInputGroup>
)
