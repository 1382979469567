import {
  Color,
  colors,
  Icons,
  Spacing,
  spacings,
} from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

type StyledSVGProps = {
  color?: Color
  size?: number | Spacing
  rotate?: string;
}

const StyledSVG = styled.svg<StyledSVGProps>`
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  color: ${({ color }) => color && colors[color]};
  font-size: ${({ size }) =>
    size && (typeof size === 'number' ? remCalc(size) : spacings[size])};
  vertical-align: middle;
  transform: ${({ rotate }) => rotate && `rotate(${rotate})`};
`

type IconProps<T = Icons> = Omit<ComponentProps<typeof StyledSVG>, 'name'> & {
  name: T
}

function Icon<T>({ name, ...otherProps }: IconProps<T>) {
  return (
    <StyledSVG {...otherProps}>
      <use xlinkHref={`#${name}`} href={`#${name}`} />
    </StyledSVG>
  )
}

export default Icon
