import {
  H1,
  H2,
  H3,
  TextLarge,
  TextSecondary,
} from '@apimmo/front/components/Typography'
import { borderRadius, Spacing, spacings } from '@apimmo/front/style/theme'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { colors, globalStyle } from '~/style/theme'

const variants = {
  main: css`
    background-color: ${colors.white};
  `,
  hollow: css`
    background-color: ${colors.white};
    border: 1px solid ${colors.grey100};
  `,
  hollowShadow: css`
    background-color: ${colors.white};
    border: 1px solid ${colors.grey100};
    box-shadow: ${globalStyle.shadow};
  `,
  secondary: css`
    background-color: ${colors.grey50};
  `,
  secondaryHollow: css`
    background-color: ${colors.grey50};
    box-shadow: inset 0 0 0 1px ${colors.grey100};
  `,
  error: css`
    background-color: ${colors.errorLight};
    box-shadow: inset 0 0 0 1px ${colors.error};
  `,
  success: css`
    background-color: ${colors.successLight};
    box-shadow: inset 0 0 0 1px ${colors.success};
  `,
  warning: css`
    background-color: ${colors.warningLight};
    box-shadow: inset 0 0 0 1px ${colors.warning};
  `,
} as const

type CardStyledProps = {
  spacingVertical?: Spacing
  spacing?: Spacing
  variant?: keyof typeof variants
  hasDropdown?: boolean
}

const slideArrowDropdown = keyframes`
  to {
    transform: translateX(-50%) rotate(-135deg) translateY(0);
    opacity: 1;
  }
`

const CardStyled = styled.div<CardStyledProps>`
  padding-top: ${({ spacingVertical, spacing }) => {
    if (spacingVertical) return spacings[spacingVertical]
    if (spacing) return spacings[spacing]
    return spacings.medium
  }};
  padding-right: ${({ spacing }) =>
    spacing ? spacings[spacing] : spacings.medium};
  padding-bottom: ${({ spacingVertical, spacing }) => {
    if (spacingVertical) return spacings[spacingVertical]
    if (spacing) return spacings[spacing]
    return spacings.medium
  }};
  padding-left: ${({ spacing }) =>
    spacing ? spacings[spacing] : spacings.medium};
  border-radius: ${borderRadius};

  ${({ variant = 'main' }) => variants[variant]};

  ${({ hasDropdown }) =>
    hasDropdown &&
    css`
      position: relative;

      &::before {
        content: '';
        z-index: 1;
        position: absolute;
        top: calc(100% + ${spacings.large});
        left: 50%;
        margin-top: -1px;
        border-radius: 2px;
        border-left: 1px solid ${colors.grey50};
        border-top: 1px solid ${colors.grey50};
        width: 20px;
        height: 20px;
        opacity: 0;
        box-shadow: -3px -3px 8px rgba(0, 0, 0, 0.04);
        background: ${colors.white};
        transform: translateX(-50%) translateY(-20px) rotate(-135deg);
        animation: ${slideArrowDropdown} 600ms 100ms ease-in forwards;
      }
    `}
`

export const CardSpacing = styled(CardStyled)``

export type CardProps = {
  title?: ReactNode
  legend?: ReactNode
  children: ReactNode
} & CardStyledProps

type CardPrimaryWithAnnotationProps = {
  annotation?: ReactNode
} & CardProps

export const BaseCard = ({ title, children, legend, ...props }: CardProps) => (
  <CardStyled {...props}>
    {title}
    {legend}
    {children}
  </CardStyled>
)

export const CardPrimary = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H1 spacingBottom="large">{title}</H1>}
    variant="main"
  >
    {children}
  </BaseCard>
)

export const CardHollow = ({
  title,
  legend,
  children,
  ...props
}: CardProps) => (
  <BaseCard
    {...props}
    title={
      <TextLarge weight="semibold" spacingBottom={legend ? undefined : 'small'}>
        {title}
      </TextLarge>
    }
    legend={
      <TextSecondary spacingBottom="small" weight="semibold">
        {legend}
      </TextSecondary>
    }
    variant="hollow"
  >
    {children}
  </BaseCard>
)

export const CardHollowShadow = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H1 spacingBottom="large">{title}</H1>}
    variant="hollowShadow"
  >
    {children}
  </BaseCard>
)

export const CardSecondary = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H1 spacingBottom="large">{title}</H1>}
    variant="secondary"
  >
    {children}
  </BaseCard>
)

export const CardSecondaryHollow = ({
  title,
  children,
  ...props
}: CardProps) => (
  <BaseCard
    {...props}
    title={<H3 spacingBottom="small">{title}</H3>}
    variant="secondaryHollow"
  >
    {children}
  </BaseCard>
)

export const CardSuccess = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H2 spacingBottom="medium">{title}</H2>}
    variant="success"
  >
    {children}
  </BaseCard>
)

export const CardWarning = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H2 spacingBottom="medium">{title}</H2>}
    variant="warning"
  >
    {children}
  </BaseCard>
)

export const CardError = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H2 spacingBottom="medium">{title}</H2>}
    variant="error"
  >
    {children}
  </BaseCard>
)

export const CardSidebar = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H3 spacingBottom="small">{title}</H3>}
    variant="main"
  >
    {children}
  </BaseCard>
)

export const CardContent = ({ title, children, ...props }: CardProps) => (
  <BaseCard
    {...props}
    title={<H2 spacingBottom="medium">{title}</H2>}
    variant="main"
  >
    {children}
  </BaseCard>
)

export const CardPrimaryWithAnnotation = ({
  title,
  children,
  annotation,
  ...props
}: CardPrimaryWithAnnotationProps) => (
  <BaseCard
    {...props}
    title={
      <>
        <TextSecondary weight="semibold" textTransform="uppercase">
          {annotation}
        </TextSecondary>
        <H1 spacingBottom="large">{title}</H1>
      </>
    }
    variant="main"
  >
    {children}
  </BaseCard>
)
