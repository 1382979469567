import { borderRadius, spacings } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'
import React, { ComponentType } from 'react'
import { StylesConfig } from 'react-select'
import { colors, globalStyle } from '~/style/theme'
import { condition } from '~/utils/selectors'
import { StyledInput } from '../Input'

/**
 * On sanitize pour virer le theme par default de ReactSelect,
 * et pour passer les props nécessaire à son fonctionnement automatiquement.
 */
const sanitizeSelectComponent = (Component: ComponentType<any>) => ({
  innerRef,
  innerProps,
  cx,
  selectProps,
  ...props
}: any) => (
  <Component
    ref={innerRef}
    {...innerProps}
    {...props}
    error={selectProps.error}
  />
)

export const StyledControl = sanitizeSelectComponent(styled(
  StyledInput.withComponent('div'),
)<{ isFocused: boolean; isDisabled: boolean }>`
  display: flex;
  padding: 0 ${spacings.xsmall};
  box-shadow: ${condition('isFocused', globalStyle.shadowFocus)};
  background-color: ${condition('isFocused', colors.white, colors.grey50)};
  pointer-events: ${condition('isDisabled', 'none')};
  border-color: ${condition('isFocused', colors.primary, 'transparent')};
  color: ${condition('isDisabled', colors.grey200)};
`)

export const StyledOption = sanitizeSelectComponent(styled.div<{
  isFocused: boolean
}>`
  display: block;
  width: 100%;
  padding: ${remCalc(12)} ${spacings.medium};
  background-color: ${condition('isFocused', colors.grey50)};
  font-size: 1rem;
  user-select: none;
  cursor: default;
`)

export const IndicatorSeparator = () => null

export const selectStyles: StylesConfig<any, boolean> = {
  container: (base, { selectProps: { isDisabled } }) => ({
    ...base,
    ...(isDisabled && {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    }),
  }),
  menu: (base) => ({
    ...base,
    overflow: 'hidden',
    zIndex: 2,
    borderRadius: `${borderRadius}`, // force string type for TS
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    empty: {
      display: 'none',
    },
  }),
  valueContainer: (base, { selectProps: { isSearchable } }) => ({
    ...base,
    ...(isSearchable && { cursor: 'text' }),
  }),
  multiValue: (base) => ({
    ...base,
    cursor: 'default',
    borderRadius: '4px',
    background: colors.grey100,
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '2px 6px 4px 6px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    cursor: 'pointer',
    '&:hover': {
      background: colors.grey200,
      color: colors.grey400,
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '>svg': {
      color: 'red',
    },
  }),
  singleValue: (base) => ({
    ...base,
    cursor: 'default',
    color: 'inherit',
  }),
}
