import {
  TypedUseSelectorHook,
  useSelector as useSelectorGeneric,
} from 'react-redux'
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState } from './types'

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorGeneric

export function useQueryParams() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
export function useSearchParams(word:string) {
  const location = useLocation()

  return useMemo(() => location.pathname.includes(word), [location.pathname, word])

}