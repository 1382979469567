import {
  colors,
  fontFamily,
  FontWeight,
  fontWeight,
  isTextColor,
  lineHeight,
  Spacing,
  spacings,
  textColors,
  TextColors,
  textSize,
} from '@apimmo/front/style/theme'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { isNil } from 'ramda'
import React, { ComponentProps, ElementType } from 'react'
import { withProps } from 'recompose'

type Display = 'block' | 'inline'

export type TypographyProps = {
  display?: Display
  color?: TextColors | 'inherit' | 'initial'
  weight?: FontWeight
  verticalMargin?: number
  maxLines?: number
  onOneLine?: boolean
  as?: ElementType
  type?: keyof typeof textSize
  spacingBottom?: Spacing
  spacingTop?: Spacing
  align?: 'center' | 'right' | 'left' | 'justify' | 'inherit'
  uppercaseFirstLetter?: boolean
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'initial'
    | 'inherit'
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit'
}

const paragraphShouldForwardProp = (prop: string) =>
  !['onOneLine'].includes(prop) && isPropValid(prop)

const ParagraphStyled = styled('p', {
  shouldForwardProp: paragraphShouldForwardProp,
})<TypographyProps>`
  display: ${({ display }) => display && display};
  margin-bottom: ${({ spacingBottom = 'null' }) => spacings[spacingBottom]};
  margin-top: ${({ spacingTop = 'null' }) => spacings[spacingTop]};
  color: ${({ color = 'default' }) =>
    isTextColor(color) ? textColors[color] : color};
  font-family: ${fontFamily};
  font-size: ${({ type = 'default' }) => textSize[type]};
  line-height: ${lineHeight.default};
  font-weight: ${({ weight = 'default' }) => fontWeight[weight]};
  text-transform: ${({ textTransform }) => textTransform && textTransform};
  text-align: ${({ align }) => align};

  ${({ onOneLine }) =>
    onOneLine &&
    `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}

  ${({ maxLines }) =>
    maxLines &&
    `
    -webkit-line-clamp: ${maxLines};
     display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}

  ${({ uppercaseFirstLetter = true }) =>
    uppercaseFirstLetter &&
    `
    &::first-letter {
      text-transform: uppercase;
    }
  `}
`

type TextProps = ComponentProps<typeof ParagraphStyled>

export const Text = ({ children, ...props }: TextProps) => {
  if (isNil(children)) return null

  return <ParagraphStyled {...props}>{children}</ParagraphStyled>
}

export const Span = styled(withProps({ as: 'span' as const })(Text))<TextProps>`
  display: ${({ display = 'initial' }) => display};
`

export const H1 = styled(withProps({ as: 'h1' as const })(Text))<TextProps>`
  font-weight: bold;
  font-size: ${textSize.xxlarge};
  line-height: ${lineHeight.medium};
`

export const H2 = styled(withProps({ as: 'h2' as const })(Text))<TextProps>`
  font-weight: bold;
  font-size: ${textSize.xlarge};
  line-height: ${lineHeight.medium};
`

export const H3 = styled(withProps({ as: 'h3' as const })(Text))<TextProps>`
  font-weight: 600;
  font-size: ${textSize.large};
  line-height: ${lineHeight.default};
`

export const HR = styled.hr`
  border: none;
  height: 2px;
  background: ${colors.grey100};
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


export const TextLarge = styled(Text)<TextProps>`
  font-size: ${textSize.medium};
  line-height: ${lineHeight.medium};
`

export const TextXLarge = styled(Text)<TextProps>`
  font-size: ${textSize.large};
  line-height: ${lineHeight.medium};
`

export const TextSmall = styled(Text)<TextProps>`
  font-size: ${textSize.small};
  line-height: ${lineHeight.small};
`

export const TextSecondary = styled(Text)<TextProps>`
  color: ${textColors.secondary};
  line-height: ${lineHeight.default};
`

export const TextSecondaryLarge = styled(Text)<TextProps>`
  font-size: ${textSize.medium};
  color: ${textColors.secondary};
  line-height: ${lineHeight.medium};
`

export const TextSecondarySmall = styled(Text)<TextProps>`
  font-size: ${textSize.small};
  line-height: ${lineHeight.small};
  color: ${textColors.secondary};
`

export const Label = styled(withProps({ as: 'label' as const })(Text))<
  TextProps
>``

export const Annotation = styled(Text)<TextProps>`
  font-size: ${textSize.small};
  font-weight: bold;
`

export const Unit = styled(Text)<TextProps>`
  color: ${textColors.secondary};
`

export const TextError = styled(TextSmall)<TextProps>`
  color: ${textColors.warning};
`
