import {
  borderRadius,
  colors,
  lineHeight,
  spacings,
  StatutColors,
  transition,
} from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'
import React, { Ref } from 'react'
import { IconCircleButton } from '../icons/IconCircle'

type TagStyledProps = {
  color?: StatutColors
  size?: 'small' | 'default' | 'large'
  isDraggable?: boolean
  isDragging?: boolean
}

const TagStyled = styled.span<TagStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${spacings.small};
  border-radius: ${borderRadius};
  ${({ isDragging }) => (isDragging ? `opacity : 0.5;` : `opacity : 1;`)}
  padding: ${({ size }) => {
    if (size === 'small') return `${remCalc(2)} ${spacings.xsmall}`
    if (size === 'large') return `${spacings.xsmall} ${spacings.small}`
    return `${remCalc(6)} ${remCalc(12)}`
  }};

  color: ${({ color }) => {
    if (color === 'primary') return colors.primaryDark
    if (color === 'secondary') return colors.secondary
    if (color === 'success') return colors.primaryDark
    if (color === 'warning') return colors.warningDark
    if (color === 'error') return colors.errorDark
    if (color === 'transparent') return 'transparent'
    if (color === 'new') return colors.white
    if (color === 'sold') return colors.warningDark
    return colors.grey300
  }};

  background-color: ${({ color }) => {
    if (color === 'success') return colors.primaryLight
    if (color === 'secondary') return colors.secondaryLightness
    if (color === 'primary') return colors.primaryLight
    if (color === 'warning') return colors.warningLight
    if (color === 'error') return colors.errorLight
    if (color === 'transparent') return 'transparent'
    if (color === 'new') return colors.new
    if (color === 'sold') return colors.warning
    return colors.grey100
  }};
  font-size: ${({ size }) => (size === 'large' ? remCalc(16) : remCalc(12))};
  font-weight: ${({ size }) => (size === 'large' ? 'bold' : '600')};
  line-height: ${lineHeight.tiny};
  white-space: nowrap;
  transition: ${transition.global};

  ${({ isDraggable }) => isDraggable && 'cursor: move;'}

  &::first-letter {
    text-transform: uppercase;
  }
`

export type TagProps = {
  label: React.ReactNode | number
  onDelete?: () => void
} & TagStyledProps

export const Tag = React.forwardRef(
  (
    {
      label,
      size = 'default',
      color = 'grey',
      isDraggable,
      isDragging,
      onDelete,
      ...props
    }: TagProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <TagStyled
      size={size}
      color={color}
      ref={ref}
      isDraggable={isDraggable}
      isDragging={isDragging}
      {...props}
    >
      {label}
      {onDelete && (
        <IconCircleButton
          color="grey"
          size="xtiny"
          icon="close"
          onClick={onDelete}
        />
      )}
    </TagStyled>
  ),
)
