import { RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import { H1, H3 } from '@apimmo/front/components/Typography'
import React from 'react'
import { IconCircleButton } from '../icons/IconCircle'

type HelmetProps = {
  title: string
  subtitle?: string | null
  onClose: () => void
}

export const Helmet = ({ title, subtitle, onClose }: HelmetProps) => (
  <RowFlex horizontalAlignment="space-between">
    <div>
      <H1>{title}</H1>
      {subtitle && <H3>{subtitle}</H3>}
    </div>

    <IconCircleButton icon="close" color="grey" onClick={onClose} />
  </RowFlex>
)
