import React, { useRef } from 'react'
import ReactSelect, { components } from 'react-select'
import { Props as ReactSelectProps } from 'react-select/base'
import { InputGroup } from '~/components/common/Input/InputGroup'
import { get } from '~/utils/get'
import {
  IndicatorSeparator,
  selectStyles,
  StyledControl,
  StyledOption,
} from './selectStyles'
import {
  InternalValue,
  ObjectOption,
  Option,
  SelectChangeEvent,
  SelectProps,
} from './selectTypes'

/** TODO A TESTER AVEC TOUS LES CAS!!*/
export const Select = <T extends Option>({
  options,
  fullWidth,
  label,
  helperText,
  errorText,
  name,
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  searchable,
  placeholder,
  getLabel,
  noOptionsMessage,
  gutterTop,
  gutterBottom,
  menuPlacement = 'auto',
  ...otherProps
}: SelectProps<T>) => {
  /**
   * Permet de simuler un SyntheticEvent standard, pour pouvoir l'utiliser comme n'importe quel element du DOM,
   * avec target.name, target.value, et donc nottament de l'utiliser avec formic sans se poser de questions.
   */
  const createOnChangeFakeEvent = (newValue: Option) =>
    (({
      target: {
        name,
        id: name,
        value: newValue,
        type: multiple ? 'select-multiple' : 'select-one',
      },
    } as unknown) as SelectChangeEvent<T>)

  const selectOptions = options.map((option) =>
    typeof option === 'string' ? { value: option, label: option } : option,
  ) as ObjectOption[]

  const selectValue = multiple
    ? selectOptions.filter((option) =>
        (Array.isArray(value) ? value : [value]).includes(
          option.value || value,
        ),
      )
    : selectOptions.find((option) =>
        option.value ? option.value === value : option === value,
      )

  const getOptionValue = (newValue: InternalValue) =>
    newValue && newValue.value ? newValue.value : newValue

  const getOptionLabel: ReactSelectProps<ObjectOption>['getOptionLabel'] = (
    option,
  ) => (typeof getLabel === 'string' ? get(option, getLabel) : getLabel(option))

  const selectOnChange = (newValue: InternalValue) =>
    onChange &&
    onChange(
      createOnChangeFakeEvent(
        Array.isArray(newValue)
          ? newValue.map(getOptionValue)
          : getOptionValue(newValue),
      ),
    )

  const selectRef = useRef<ReactSelectProps['ref']>({
    focus: () => {
      // Vide en attendant que la ref s'initialise.
    },
  })

  const NoOptionsMessage = (props: any) => (
    <components.NoOptionsMessage {...props}>
      {noOptionsMessage}
    </components.NoOptionsMessage>
  )

  return (
    <InputGroup
      name={name}
      fullWidth={fullWidth}
      label={label}
      helperText={helperText}
      errorText={errorText}
      onClick={() => selectRef.current.focus()}
      gutterTop={gutterTop}
      gutterBottom={gutterBottom}
    >
      <ReactSelect
        isMulti={multiple}
        isClearable={clearable}
        isDisabled={disabled}
        isSearchable={searchable}
        name={name}
        id={name}
        error={!!errorText}
        options={selectOptions}
        value={selectValue}
        onChange={selectOnChange}
        placeholder={placeholder}
        styles={selectStyles}
        getOptionLabel={getOptionLabel}
        menuPlacement={menuPlacement}
        components={{
          Control: StyledControl,
          Option: StyledOption,
          IndicatorSeparator,
          NoOptionsMessage,
        }}
        ref={selectRef}
        {...otherProps}
      />
    </InputGroup>
  )
}

Select.defaultProps = {
  placeholder: '',
  searchable: false,
  getLabel: 'label',
  noOptionsMessage: "Pas d'option disponible.",
}
