export enum SignatureType {
  RENDU_ESTIMATION = 'rendu_estimation',
  MANDAT = 'mandat',
  MANDAT_AVENANT = 'mandat_avenant',
}

export const SIGNATURE_TYPE_LABEL: Record<SignatureType, string> = {
  [SignatureType.RENDU_ESTIMATION]: 'Rendu d‘estimation',
  [SignatureType.MANDAT]: 'Mandat',
  [SignatureType.MANDAT_AVENANT]: 'Avenant au mandat',
}

export enum ListSignatureSort {
  CREATED_AT_DESC = 'createdAt,desc',
  CREATED_AT_ASC = 'createdAt,asc',
}

export const LIST_SIGNATURE_SORT_LABEL: Record<ListSignatureSort, string> = {
  [ListSignatureSort.CREATED_AT_DESC]: 'Du plus récent',
  [ListSignatureSort.CREATED_AT_ASC]: 'Du plus ancien',
}