import {
  FieldInputProps,
  FormikContextType,
  FormikValues,
  useField,
  useFormikContext,
} from 'formik'
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FocusEventHandler,
  SyntheticEvent,
} from 'react'
import { useDebounce } from 'react-use'
import { Input } from '~/components/common/Input/Input'
import { Diff } from '~/utils/types'

export type InputFieldProps<Values extends FormikValues> = Diff<
  ComponentProps<typeof Input>,
  FieldInputProps<any> & { errorText: string }
> & {
  name: string
  defaultValue?: Values[keyof Values]
  onBlur?: (
    e: SyntheticEvent<HTMLInputElement>,
    context: FormikContextType<Values>,
  ) => void
  formatValue?: (
    value: Values[keyof Values],
    context: FormikContextType<Values>,
  ) => any
  onChange?: (
    e: ChangeEvent<HTMLInputElement>,
    context: FormikContextType<Values>,
  ) => void
  submitOnBlur?: boolean
  submitOnChange?: boolean
}

export const InputField = ({
  name,
  defaultValue,
  onBlur,
  onChange,
  formatValue,
  submitOnBlur = false,
  submitOnChange = false,
  ...props
}: InputFieldProps<any>) => {
  const [{ value, ...field }, { initialValue, touched, error }] = useField(name)
  const context = useFormikContext<FormikValues>()
  const {handleSubmit} = context
 
  const handleBlur: FocusEventHandler<HTMLInputElement> | undefined = (e) => {
    if (onBlur) {
      onBlur(e, context)
      field.onBlur(e)
    }
    if (submitOnBlur) context.handleSubmit()
  }

  useDebounce(
    () => {
      if (submitOnChange && value !== initialValue) handleSubmit()
    },
    300,
    [value, touched, initialValue],
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> | undefined = (e) =>
    onChange ? onChange(e, context) : field.onChange(e)

  // To display Field Arrays errors
  const fieldError = context.errors[name]
  const asValueDefined = value !== undefined ? value : defaultValue
  const asFormatValue = formatValue ? formatValue(asValueDefined, context) : asValueDefined

  

  return (
    <Input
      {...props}
      {...field}
      errorText={touched && (error || fieldError)}
      onBlur={handleBlur}
      onChange={handleChange}
      value={asFormatValue}
  
    />
  )
}
