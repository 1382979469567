import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from 'formik'
import React from 'react'
import { extractValidationErrors } from '~/utils/validation'

type FormProps<Values> = Omit<FormikConfig<Values>, 'onSubmit'> & {
  rules?: { [K in keyof Values]: string } | string
  onSubmit?: FormikConfig<Values>['onSubmit']
  validationKeysMapping?: Partial<Record<keyof Values, string>>
}

export function Form<Values extends FormikValues>({
  children,
  rules,
  onSubmit,
  validationKeysMapping,
  enableReinitialize = true,
  ...props
}: FormProps<Values>) {
  const handleSubmit = async (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    if (onSubmit) {
      try {
        await onSubmit(values, formikHelpers)
      } catch (e) {
        formikHelpers.setErrors(
          // @ts-ignore
          extractValidationErrors(e, validationKeysMapping),
        )
      }
    }
  }

  return (
    <Formik
      {...props}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      enableReinitialize={enableReinitialize}
    >
      <FormikForm>{children}</FormikForm>
    </Formik>
  )
}
