import Icon from '@apimmo/front/components/icons/Icon'
import { colors, Icons, spacings, transition } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

type CircleStyledProps = {
  color?: keyof typeof variants
  size?: 'xtiny' | 'tiny' | 'small' | 'large' | 'medium'
  disabled?: boolean
}

const circleSize = {
  xtiny: spacings.small,
  tiny: spacings.medium,
  small: spacings.large,
  medium: spacings.xlarge,
  large: spacings.xxlarge,
}
const circleIconSize = {
  xtiny: spacings.xsmall,
  tiny: remCalc(12),
  small: remCalc(12),
  medium: spacings.small,
  large: spacings.medium,
}

const variants = {
  primary: css`
    background-color: ${colors.primaryLight};
    color: ${colors.primary};
  `,
  secondary: css`
    background-color: ${colors.secondaryLightness};
    color: ${colors.secondaryLight};
  `,
  grey: css`
    background-color: ${colors.grey100};
    color: ${colors.grey400};
  `,
  white: css`
    background-color: ${colors.white};
    color: ${colors.grey400};
  `,
  hollow: css`
    background-color: ${colors.white};
    color: ${colors.primary};
    box-shadow: inset 0 0 0 2px ${colors.primaryLight};
  `,
  warning: css`
    background-color: ${colors.primaryLight};
    color: ${colors.primary};
  `,
  success: css`
    background-color: ${colors.successLight};
    color: ${colors.success};
  `,
  error: css`
    background-color: ${colors.errorLight};
    color: ${colors.error};
  `,
  transparent: css`
    background-color: transparent;
    color: ${colors.grey400};
  `,
} as const

const variantsHover = {
  primary: css`
    background-color: ${colors.primary};
    color: ${colors.primaryLight};
    box-shadow: 0 0 0 6px ${colors.primary};
  `,
  secondary: css`
    background-color: ${colors.secondaryLighter};
    color: ${colors.secondary};
    box-shadow: 0 0 0 6px ${colors.secondaryLighter};
  `,
  grey: css`
    background-color: ${colors.grey200};
    color: ${colors.white};
    box-shadow: 0 0 0 6px ${colors.grey200};
  `,
  white: css`
    background-color: ${colors.grey100};
    box-shadow: 0 0 0 6px ${colors.grey100};
  `,
  hollow: css`
    background-color: ${colors.primaryLight};
    color: ${colors.primary};
    box-shadow: 0 0 0 6px ${colors.primaryLight};
  `,
  warning: css`
    background-color: ${colors.warning};
    color: ${colors.white};
    box-shadow: 0 0 0 6px ${colors.warning};
  `,
  success: css`
    background-color: ${colors.success};
    color: ${colors.white};
    box-shadow: 0 0 0 6px ${colors.success};
  `,
  error: css`
    background-color: ${colors.error};
    color: ${colors.white};
    box-shadow: 0 0 0 6px ${colors.error};
  `,
  transparent: css`
    background-color: ${colors.grey200};
    color: ${colors.white};
    box-shadow: 0 0 0 6px ${colors.grey200};
  `,
} as const

const Circle = styled.span<CircleStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ size = 'small' }) =>
    size &&
    `
    width: ${size ? circleSize[size] : circleSize.small};
    height: ${size ? circleSize[size] : circleSize.small};
    font-size: ${size ? circleIconSize[size] : circleSize.small};
  `}

  ${({ color = 'primary' }) => variants[color]};
`

const CircleButton = styled(IconCircle)<IconCircleProps>`
  position: relative;
  border: 0;
  transition: ${transition.global};
  appearance: none;
  padding: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
    color: ${colors.grey100};
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: -${spacings.xsmall};
    left: -${spacings.xsmall};
    right: -${spacings.xsmall};
    bottom: -${spacings.xsmall};
    cursor: pointer;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    outline: none;
    ${({ color = 'primary' }) => variantsHover[color]};
`

export type IconCircleProps<T = Icons> = {
  icon: T
  as?: 'span' | 'button' | 'a'
} & CircleStyledProps

function IconCircle<T>({
  icon,
  size,
  color,
  as = 'span',
  disabled,
  ...props
}: IconCircleProps<T>) {
  const Component = Circle.withComponent(as)
  return (
    <Component disabled={disabled} size={size} color={color} {...props}>
      <Icon name={icon} />
    </Component>
  )
}

export default IconCircle

type IconCircleButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  IconCircleProps

type IconCircleLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  IconCircleProps

export const IconCircleButton = ({ ...props }: IconCircleButtonProps) => (
  <CircleButton as="button" {...props} />
)

export const IconCircleAnchor = ({ ...props }: IconCircleLinkProps) => (
  <CircleButton as="a" {...props} />
)
