import Icon from '@apimmo/front/components/icons/Icon'
import IconCircle from '@apimmo/front/components/icons/IconCircle'
import { ColumnFlex, RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import Modal from '@apimmo/front/components/Modal'
import { Tag } from '@apimmo/front/components/Tag/Tag'
import { Text, TextLarge } from '@apimmo/front/components/Typography'
import React, { ReactNode } from 'react'
import { SyncLoader } from 'react-spinners'
import { Button } from '~/components/common/Buttons/Button'
import { colors } from '~/style/theme'

type DeleteSignatureModalProps = {
  title: ReactNode
  open: boolean
  loading: boolean
  success: boolean
  error: unknown
  onClose: () => void
  onConfirm: () => void
}

const DeleteSignatureModal = ({
  title,
  open,
  loading,
  success,
  error,
  onClose,
  onConfirm,
}: DeleteSignatureModalProps) => (
    <Modal isOpen={open} onClose={onClose} title="Supprimer la signature" size="small">
      {loading ? (
        <ColumnFlex gap='small' horizontalAlignment="center">
          <SyncLoader size={10} color={colors.secondaryLight} />
          <TextLarge color="secondaryLight">Suppression en cours.</TextLarge>
        </ColumnFlex>
      ) : null}
      {success ? (
        <ColumnFlex gap='small' horizontalAlignment="center">
          <Icon name="check" size="large" color="primary"/>
          <TextLarge color="secondaryLight">Signature supprimée.</TextLarge>
        </ColumnFlex>
      ) : null}
      {!success && !loading ? (
        <ColumnFlex
          horizontalAlignment="center"
          gap="medium"
        >
          <div>
            <TextLarge weight="semibold">
              Vous allez supprimer la signature de :
            </TextLarge>
            <Text spacingBottom='medium' spacingTop='medium' align="center">
              <Tag label={title}/>
            </Text>
            <TextLarge weight="semibold">
              Si la signature est en cours, les signataires seront prévenus de l‘annulation de la signature.
            </TextLarge>
          </div>
          <RowFlex horizontalAlignment="center" gap="xsmall">
            <Button level="secondary" label="Retour" onClick={onClose}/>
            <Button level="alert" label="Confirmer" onClick={onConfirm}/>
          </RowFlex>
            {error ? (
              <Text color="error">
                Une erreur inconnue s‘est produite, merci de réessayer plus tard ou de prévenir l‘administration.
              </Text>
            ) : null}
        </ColumnFlex>
        ) : null}
    </Modal>
  )

export default DeleteSignatureModal
