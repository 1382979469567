import {
  borderRadius,
  colors,
  spacings,
  textSize,
  transition,
} from '@apimmo/front/style/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'

export const Table = styled.table<{ full?: boolean }>`
  border-radius: ${borderRadius};
  border: 1px solid ${colors.grey100};
  border-spacing: 0;

  ${({ full }) => full ? `
    width: 100%;
  ` : ''};
`

const BaseCell = css`
  padding: ${spacings.small};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

export const CellThead = styled.th`
  ${BaseCell};
  border-color: ${colors.grey100};
  color: ${colors.grey200};
  text-align: left;
  text-transform: uppercase;
  font-size: ${textSize.tiny};
  font-weight: 600;
`

export const CellTbody = styled.td<{ align?: 'left' | 'right' }>`
  ${BaseCell};
  border-color: ${colors.grey50};
  ${({ align = 'left' }) => `
    text-align: ${align === 'left' ? 'left' : 'right'}
  `};
`

export const RowTbody = styled.tr`
  transition: ${transition.global};
  &:hover {
    background: ${rgba(colors.grey50, 0.4)};
  }
`

export const CellTbodyLink = styled(Link)`
  width: 100%;
  height: 100%;
  border-color: transparent;
`
