import {
  FieldInputProps,
  FormikValues,
  useField,
  useFormikContext,
} from 'formik'
import { omit } from 'ramda'
import React, { ComponentProps } from 'react'
import { Diff } from '~/utils/types'
import { Select } from '../common/Input/Select/Select'
import { SelectChangeEvent } from '../common/Input/Select/selectTypes'

type SelectFieldProps = Diff<
  ComponentProps<typeof Select>,
  FieldInputProps<any> & { errorText: string }
> & {
  name: string
  multiple?: boolean
  disabled?: boolean
  required?: boolean
  onChange?: (event: SelectChangeEvent<any>) => void
  submitOnChange?: boolean
}

export const SelectField = ({
  name,
  submitOnChange = false,
  ...props
}: SelectFieldProps) => {
  const [field, { error, touched }, { setTouched }] = useField(name)
  const { handleSubmit } = useFormikContext<FormikValues>()

  /* Le onBlur donné par formik met le field à touched
  Le faire au blur cause un bug chelou qui empêche le focus direct sur un autre élément du DOM
  On passe ici le field à touched au onChange pour avoir le même résultat

  ⚠️ Bien penser en cas de refacto à override le onBlur de formik pour donner le bon name au champs
  Sinon il prend l'id donné par React-Select qui n'a pas de sens
  Lui passer onBlur = () => { setTouched(true) }
  */
  const fieldWithoutBlur = omit(['onBlur'], field)
  const onChange = (event: SelectChangeEvent<any>) => {
    field.onChange(event)
    if (props.onChange) props.onChange(event)
    if (!touched) setTouched(true)
    if (submitOnChange) handleSubmit()
  }

  return (
    <Select
      {...props}
      {...fieldWithoutBlur}
      onChange={onChange}
      errorText={error}
    />
  )
}

SelectField.defaultProps = Select.defaultProps
