import { useState, useEffect, useCallback } from 'react'
import { useUnmount } from 'react-use'

export const useModal = (initialState = false) => {
  const [isModalOpen, setIsModalOpen] = useState(initialState)

  const displayModal = (toDisplay: boolean) => {
    if (toDisplay) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
    setIsModalOpen(toDisplay)
  }

  useUnmount(() => displayModal(false))

  const openModal = () => displayModal(true)

  const closeModal = () => displayModal(false)

  return {
    isModalOpen,
    openModal,
    closeModal,
  }
}

export const useCloseOnEscape = (
  isOpen: boolean,
  closeFunction: () => void,
) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen || event.key !== 'Escape') return

      closeFunction()
    },
    [isOpen, closeFunction],
  )

  return useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}
