import React, { useRef } from 'react'
import { useClickAway } from 'react-use'
import { ColumnFlex } from '../layouts/GridFlex'
import { Helmet } from './Helmet'
import { useCloseOnEscape } from './Modal.hooks'
import { Container, Overlay } from './Modal.style'

export type ModalProps = {
  children: React.ReactNode
  title: string
  subtitle?: string | null
  size?: 'default' | 'large' | 'small' | 'fullscreen'
  onClose: () => void
  isOpen: boolean
}

const Modal = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  size = 'default',
}: ModalProps) => {
  useCloseOnEscape(isOpen, onClose)

  const ref = useRef(null)
  useClickAway(ref, () => {
    if (isOpen) onClose()
  })

  if (!isOpen) return null

  return (
    <Overlay>
      <Container size={size} ref={ref}>
        <ColumnFlex horizontalAlignment="stretch" gap="xxlarge">
          <Helmet title={title} subtitle={subtitle} onClose={onClose} />
          <div>{children}</div>
        </ColumnFlex>
      </Container>
    </Overlay>
  )
}

export default Modal
