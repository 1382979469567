import IconCircle from '@apimmo/front/components/icons/IconCircle'
import { colors, Icons, spacings, transition } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import React from 'react'

type ButtonToolsProps = {
  disabled?: boolean
  icon: Icons
  iconPosition?: 'left' | 'right'
  color?: 'hollow' | 'grey'
} & JSX.IntrinsicElements['button']

const IconCircleHoverStyled = {
  hollow: colors.primary,
  grey: colors.grey200,
}
const IconHoverStyled = {
  hollow: colors.white,
  grey: colors.white,
}

const IconCircleStyled = styled(IconCircle)<ButtonToolsProps>``

const ButtonWithIconStyled = styled('button')<ButtonToolsProps>`
  display: inline-flex;
  align-items: center;
  gap: ${spacings.small};
  padding: 0;
  border: 0;
  background: none;
  color: ${colors.grey400};
  transition: ${transition.global};
  cursor: pointer;

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    `
    &>*:first-child {
      order: 1;
    }
  `}

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    ${IconCircleStyled} {
      box-shadow: none;
      background-color: ${({ color }) =>
        IconCircleHoverStyled[color || 'grey']};
      color: ${({ color }) => IconHoverStyled[color || 'grey']};
    }
  }

  &:disabled {
    color: ${colors.grey200};
    cursor: default;
    * {
      box-shadow: none;
      color: inherit;
    }
  }
`

export const ButtonWithIconCircle = ({
  disabled,
  icon,
  iconPosition = 'left',
  children,
  color = 'grey',
  ...otherProps
}: ButtonToolsProps) => (
  <ButtonWithIconStyled
    {...otherProps}
    icon={icon}
    disabled={disabled}
    iconPosition={iconPosition}
    color={color}
  >
    <IconCircleStyled disabled={disabled} icon={icon} color={color} />
    <span>{children}</span>
  </ButtonWithIconStyled>
)
