import Icon from '@apimmo/front/components/icons/Icon'
import { Text, TextLarge, TextSmall } from '@apimmo/front/components/Typography'
import { RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import {
  borderRadius,
  colors,
  fontFamily,
  Icons,
  spacings,
  textColors,
  transition,
} from '@apimmo/front/style/theme'
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import React, { ReactNode } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { withProps } from 'recompose'
import { FC } from '~/utils/types'

const colorIcon = {
  default: 'grey200',
  primary: 'primary',
  error: 'error',
  secondary: 'secondary',
  white: 'white',
  secondaryLight: 'secondaryDark',
} as const

type LinkTypeProps = {
  color?: keyof typeof colorIcon
  disabled?: boolean
  underline?: boolean
  iconPosition?: 'left' | 'right'
  nestedLink?: boolean
}

const LinkStyled = styled(RowFlex, {
  shouldForwardProp: isPropValid,
})<LinkTypeProps>`
  display: inline-flex;
  position: ${({ nestedLink }) => (nestedLink ? null : 'relative')};
  padding: 0;
  border: none;
  background: none;
  font-family: ${fontFamily};
  text-decoration: ${({ underline }) => (underline ? 'underline' : null)};
  line-height: 1;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  -webkit-appearance: none;

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    `
    &>*:first-child {
      order: 1;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .7;
  `}

  > * {
    position: relative;
  }

  /* The pseudo element expand the click area */
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    border-radius: ${borderRadius};
    top: -${spacings.xxsmall};
    left: -${spacings.xsmall};
    right: -${spacings.xsmall};
    bottom: -${spacings.xxsmall};
    transition: ${transition.global};
    opacity: 0;
    background-color: ${rgba(colors.grey400, 0.05)};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: underline;

    text-decoration-color: ${({ color }) =>
      color ? textColors[color] : textColors.default};

    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

const mapSizeLabelComponent = {
  large: TextLarge,
  small: TextSmall,
  default: Text,
} as const

export type BaseLinkProps = {
  icon?: Icons
  label: string | ReactNode
  size?: keyof typeof mapSizeLabelComponent
  href?: string
} & LinkTypeProps

export const BaseLink = ({
  icon,
  label,
  color = 'default',
  size = 'default',
  nestedLink = false,
  disabled,
  underline,
  iconPosition = 'left',
  ...props
}: BaseLinkProps) => {
  const LabelComponent = mapSizeLabelComponent[size]
  return (
    <LinkStyled
      gap="xsmall"
      verticalAlignment="center"
      disabled={disabled}
      color={color}
      iconPosition={iconPosition}
      underline={underline}
      nestedLink={nestedLink}
      {...props}
    >
      {icon && (
        <Icon
          name={icon}
          color={disabled ? colorIcon.default : colorIcon[color]}
        />
      )}
      <LabelComponent
        as="span"
        weight="semibold"
        color={disabled ? 'default' : color}
      >
        {label}
      </LabelComponent>
    </LinkStyled>
  )
}

type LinkProps = FC<BaseLinkProps & RouterLinkProps & { as: typeof RouterLink }>
export const Link = withProps({ as: RouterLink })(BaseLink as LinkProps)

type ExternalLinkType = FC<
  BaseLinkProps & JSX.IntrinsicElements['a'] & { as: 'a' }
>
export const ExternalLink = withProps({ as: 'a' })(BaseLink as ExternalLinkType)
