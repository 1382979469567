import { StatutColors, statutColors } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'

export type ProgressBarProps = {
  label?: React.ReactNode
  value?: React.ReactNode
  min: number
  max: number
  now: number
  text?: string
  color?: StatutColors
  width: string
}

const Root = styled.div<Pick<ProgressBarProps, 'width'>>`
  border-radius: ${remCalc(6)};
  width: ${({ width }) => width ?? 'auto'};
`

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: ${remCalc(11)};
`

const Back = styled.div<Pick<ProgressBarProps, 'color'>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color ? statutColors[color] : statutColors.grey};
  border-radius: ${remCalc(6)};
  opacity: 0.33;
`

const Front = styled.div<Pick<ProgressBarProps, 'color'|'width'>>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width ?? '100%'};
  height: 100%;
  background-color: ${({ color }) => color ? statutColors[color] : statutColors.grey};
  border-radius: ${remCalc(6)};
`

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`

const Label = styled.div`
  font-weight: bold;
`

export const ProgressBar = ({
    label,
    value,
    min,
    max,
    now,
    text,
    color,
    width,
  }: ProgressBarProps) => {
    const frontWidth = now / max * 100

    return (
    <Root
      role="progressbar"
      aria-valuenow={now}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuetext={text}
      width={width}
    >
      <Text>
        {label ? <Label>{label}</Label> : null}
        {value ? <div>{value}</div> : null}
      </Text>
      <Bar>
        <Front color={color} width={`${frontWidth}%`}/>
        <Back color={color}/>
      </Bar>
    </Root>
  )
}
  